import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FeatureBox from "../components/feature-box";
import SEO from "../components/seo";
import Skel from "../components/skel";
import Testimonial from "../components/testimonial";
import { SIGNUP_URL } from "../util/constants";
import "./index.scss";


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mainTvImage: file(
        relativePath: { eq: "mockups/standalone-tv-pro-menu.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      desktopDisplayEditor: file(
        relativePath: { eq: "mockups/desktop-display-editor.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      wallTv3: file(relativePath: { eq: "mockups/wall-tv-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      webMenu1: file(relativePath: { eq: "screenshots/pro-web-menu.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      printMenuTable: file(
        relativePath: { eq: "mockups/print-menu-table-cropped.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      woodenTvCropped: file(
        relativePath: { eq: "mockups/wooden-tv-cropped.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      sampleGenericMenu: file(relativePath: { eq: "sample-generic-menu.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      tapsOnMac: file(relativePath: { eq: "mockups/taps-on-mac.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      phoneAndLaptop: file(
        relativePath: { eq: "mockups/phone-and-laptop-01.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      pairingTv: file(relativePath: { eq: "pairing-tv.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      pairingPaired: file(relativePath: { eq: "pairing-paired.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      fireStick: file(relativePath: { eq: "fire-stick.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      brewersfriend: file(
        relativePath: { eq: "integrations/brewers-friend.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      brewfather: file(relativePath: { eq: "integrations/brewfather.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      plaato: file(relativePath: { eq: "integrations/plaato.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      moonrakerLogo: file(
        relativePath: { eq: "customer-logos/moonraker.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      bareArmsLogo: file(relativePath: { eq: "customer-logos/bare-arms.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      finalGravityLogo: file(
        relativePath: { eq: "customer-logos/final-gravity.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      pubDogLogo: file(relativePath: { eq: "customer-logos/pubdog.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Skel fixedHeader>
      <SEO title="Instant digital beer and beverage menus for TV, Print, Web, and QR, by Taplist.io" />
      <main>
        <section id="home" className="gray-bg overflow-hidden">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6 m-50px-tb">
                <h1 className="dark-color display-4 m-20px-b">
                  Instant Digital Menu Boards
                </h1>
                <p className="font-2">
                  Build a beautiful, always up-to-date food &amp; beverage menu
                  in minutes. Show it on any HD TV. Print and QR menus, too!
                </p>
                <div className="p-10px-t">
                  <a
                    className="m-btn m-btn-theme m-btn-radius"
                    href={SIGNUP_URL}
                  >
                    Start Now For Free
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <Img
                  className="max-width-auto"
                  fluid={data.mainTvImage.childImageSharp.fluid}
                  loading="eager"
                  fadeIn={false}
                  alt="Brewpub menu on a TV"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="how-it-works" className="section effect-section">
          <Container>
            <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
              <Col lg={8}>
                <h3 className="h1 m-15px-b">
                  {/* Beautiful, Always-Accurate Menus */}
                  Save Time. Look Great. Sell More.
                </h3>
                <p className="m-0px font-2">
                  Keep your menus{" "}
                  <b>
                    up-to-date <i>everywhere</i>
                  </b>{" "}
                  by using Taplist.io. Add, remove, and change products from
                  anywhere &amp; publish them instantly.
                </p>
              </Col>
            </Row>
            <Row className="md-m-25px-b m-45px-b justify-content-center">
              <Col lg={4}>
                <div className="border-radius-10 box-shadow-hover hover-top white-bg p-20px-tb">
                  <div className="p-30px">
                    <h3 className="m-15px-b">TV Menu Boards</h3>
                    <Img
                      className="max-width-auto"
                      fluid={data.wallTv3.childImageSharp.fluid}
                      loading="eager"
                      alt="A successfully paired Taplist.io TV device"
                    />
                    <p>
                      Link one or more TVs to your account, customize the
                      appearance, and watch your menus update instantly.
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="border-radius-10 box-shadow-hover hover-top white-bg p-20px-tb">
                  <div className="p-30px">
                    <h3 className="m-15px-b">Web/QR Menus</h3>
                    <Img
                      className="max-width-auto"
                      fluid={data.phoneAndLaptop.childImageSharp.fluid}
                      loading="eager"
                      alt="A beverage menu web page"
                    />
                    <p>
                      Get an instant QR code beer and beverage menu, and embed
                      your menu on any web page.
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="border-radius-10 box-shadow-hover hover-top white-bg p-20px-tb">
                  <div className="p-30px">
                    <h3 className="m-15px-b">Printed Menus</h3>
                    <Img
                      className="max-width-auto"
                      fluid={data.printMenuTable.childImageSharp.fluid}
                      loading="eager"
                      alt="A printed Taplist.io menu"
                    />
                    <p>
                      Automatically generate and download custom, printable PDFs
                      of your food and beverage menus.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="font-2 font-w-600  justify-content-center text-center">
              <Col lg={8}>
                <p>
                  <b>Food and Beverages together:</b> Our <em>Pro</em> menu
                  editor supports listing food and any other product type along
                  with beverages, on a single menu. No more frustrated customers
                  switching between two different menus.
                </p>
              </Col>
            </Row>

            {/* 
              <Col lg={8}>
                <Img
                  fluid={data.tapsOnMac.childImageSharp.fluid}
                  loading="eager"
                  alt="A beverage menu web page"
                />
              </Col>
              <Col lg={4}>
                <h2 className="m-20px-b">Getting started is easy</h2>
                <ul className="list-type-03">
                  <li>
                    <i className="fas fa-check"></i> Create your menu and add
                    beverages and other products.
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Set prices and edit details
                    in your own private product database.
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Updates appear in realtime
                    on your web and TV menus.
                  </li>
                </ul>
                <p>
                  See why <b>thousands of people</b> have chosen Taplist.io as
                  the <b>fastest, easiest to customize</b> menu choice.
                </p>
                <p>
                  <a
                    class="m-btn m-btn-theme2nd m-btn-radius"
                    href="https://app.taplist.io/signup"
                    target="_blank"
                  >
                    Get Started
                  </a>
                </p>
              </Col>
            </Row> */}
          </Container>
        </section>

        <section id="tv-menus" className="section gray-bg">
          <Container>
            <Row className="md-m-25px-b m-45px-b justify-content-center">
              <Col lg={6}>
                <h3 className="h1 m-15px-b">TV Menu Boards</h3>
                <p className="m-10px-b font-2 font-w-600">
                  Use our <b>free TV app</b> to display your live-reloading menu
                  on TVs at your bar, brewery, or restaurant.
                </p>
                <ul className="list-type-03">
                  <li>
                    <i className="fas fa-check"></i>{" "}
                    <span>
                      Beautiful <b>built-in themes and HD images</b> so that you
                      can get going right away. <b>Incredibly customizable</b>{" "}
                      with no designer necessary.
                    </span>
                  </li>
                  <li>
                    <i className="fas fa-check"></i>{" "}
                    <span>
                      Supports both <b>horizontal and vertical</b> TV setups.
                    </span>
                  </li>
                  <li>
                    <i className="fas fa-check"></i>{" "}
                    <span>
                      Lightning fast <b>instant updates</b> using our advanced
                      global push network.
                    </span>
                  </li>
                  <li>
                    <i className="fas fa-check"></i>{" "}
                    <span>
                      Upload your own{" "}
                      <b>custom full-screen PNG or JPEG images</b> to promote
                      your own events and specials.
                    </span>
                  </li>
                  {/* <li>
                    <i className="fas fa-check"></i>
                    <span>
                      The <b>most reliable, battle-tested platform</b> in the
                      business, with over 2 million crash-free hours of
                      worldwide usage.
                    </span>
                  </li> */}
                </ul>
                <p>
                  We've got the most complete hardware support in the business:
                  Our apps run on <b>Fire TV</b>, <b>Apple TV</b>,{" "}
                  <b>Chromecast with Google TV</b>, and <b>Roku</b>.{" "}
                  {/* <small><br/>Dive in to our <Link to={'/hardware'}>hardware support &raquo;</Link>.</small> */}
                </p>
                {/* <p>
                  <a
                    class="m-btn m-btn-theme2nd m-btn-radius"
                    href="https://taplist.io/brewspot"
                    target="_blank"
                  >
                    See an Example
                  </a>
                </p> */}
              </Col>
              <Col lg={6}>
                <Img
                  fluid={data.woodenTvCropped.childImageSharp.fluid}
                  alt="A digital beer menu on a TV"
                />
              </Col>
            </Row>
          </Container>
        </section>

        <section id="web-menus" className="section">
          <Container>
            <Row className="md-m-25px-b m-45px-b justify-content-center">
              <Col lg={6}>
                <Img
                  fluid={data.webMenu1.childImageSharp.fluid}
                  alt="A beverage menu web page"
                />
              </Col>
              <Col lg={6}>
                <h3 className="h1 m-15px-b">Web &amp; QR Code Menus</h3>
                <p className="m-10px-b font-2 font-w-600">
                  Every account comes with a unique, customizable web menu. You
                  can share your menu as its own site, or embed it in your
                  existing web site.
                </p>
                <ul className="list-type-03">
                  <li>
                    <i className="fas fa-check"></i> Lightning-fast, 24/7 web
                    page and image hosting. It's all built in.
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Host your menu on a custom
                    web address, and optional custom domain.
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Updates appear in realtime
                    on your web and TV menus.
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Generate and print QR codes
                    in the dashboard.
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Embed mode lets you include
                    your menu on other sites.
                  </li>
                </ul>
                <p>
                  <a
                    class="m-btn m-btn-theme2nd m-btn-radius"
                    href="https://taplist.io/brewspot"
                    target="_blank"
                  >
                    See an Example
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          id="print"
          className="section gray-bg"
          style={{ paddingBottom: "0.5em" }}
        >
          <Container>
            <Row className="md-m-25px-b m-45px-b justify-content-center">
              <Col lg={6}>
                <h3 className="h1 m-15px-b">Printed Menus</h3>
                <p className="m-10px-b font-2 font-w-600">
                  Create, customize, and download <b>printable PDF menus</b>{" "}
                  using the Taplist.io dashboard.
                </p>
                <ul className="list-type-03">
                  <li>
                    <i className="fas fa-check"></i>{" "}
                    <span>
                      Customize <b>fonts, layouts, sizes, and more</b> with our
                      built-in print menu editor.
                    </span>
                  </li>
                  <li>
                    <i className="fas fa-check"></i>{" "}
                    <span>
                      Choose to show all menu sections or just the ones you
                      pick.
                    </span>
                  </li>
                  <li>
                    <i className="fas fa-check"></i>{" "}
                    <span>
                      Clean and simple built-in template that can handle large
                      menus as well as small menus.
                    </span>
                  </li>
                </ul>
                <p>
                  Beer, wine, cocktails, food, and more on a single menu. Why
                  use anything else?
                </p>
                {/* <p>
                  <a
                    class="m-btn m-btn-theme2nd m-btn-radius"
                    href="https://taplist.io/brewspot"
                    target="_blank"
                  >
                    See an Example
                  </a>
                </p> */}
              </Col>
              <Col lg={6}>
                <Img
                  fluid={data.printMenuTable.childImageSharp.fluid}
                  alt="Printed brewpub menu"
                />
              </Col>
            </Row>
          </Container>
        </section>

        <section id="features" className="section">
          <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
            <Col lg={8}>
              <h3 className="h1 m-15px-b">Packed with Power</h3>
              <p className="m-0px font-2">
                Don't be fooled by imitators. Taplist.io has been{" "}
                <b>the tried and true choice since 2018</b>. Simple to get
                started, but packed with powerful features you won't find in
                other products.
              </p>
            </Col>
          </Row>
          <Row
            className="md-m-25px-b m-45px-b justify-content-center text-center"
            style={{ padding: "1em" }}
          >
            <FeatureBox
              headline={"Automatic Illustrations"}
              icon="fa-edit"
              description={<>Built-in keg and glassware illustrations.</>}
            />
            <FeatureBox
              headline={"Advanced Metadata"}
              icon="fa-chart-line"
              description={<>Supports IBU, OG, FG, SRM, and more.</>}
            />
            <FeatureBox
              headline={"Mobile Friendly"}
              icon="fa-mobile-alt"
              description={
                <>Our dashboard works great in any mobile web browser.</>
              }
            />
            <FeatureBox
              headline={"HD Background Images"}
              description={
                <>Built-in library of rich, stylish background images.</>
              }
              icon="fa-images"
            />
            <FeatureBox
              headline={"Team Mode"}
              description={
                <>Invite staff and teammates to co-manage your menu.</>
              }
              icon="fa-users"
            />
            <FeatureBox
              headline={"Multi Location Manager"}
              description={
                <>Invite staff and teammates to co-manage your menu.</>
              }
              icon="fa-map"
            />
            <FeatureBox
              headline={<>Prices &amp; Serving Sizes</>}
              description={<>Specify prices, serving sizes, and containers.</>}
              icon="fa-wine-bottle"
            />
            <FeatureBox
              headline={"Currency Support"}
              description={<>Set prices in any world currency.</>}
              icon="fa-euro-sign"
            />
            <FeatureBox
              headline={"Keg Management"}
              description={
                <>Move kegs to different statuses in the Keg Room.</>
              }
              icon="fa-tasks"
            />
            <FeatureBox
              headline={"Advanced Integrations"}
              description={<>Works with Brewfather, Kegtron, and more.</>}
              icon="fa-sim-card"
            />
          </Row>
          <Row className="font-2 font-w-600  justify-content-center text-center">
            <Col lg={8}>
              <p>
                <b>...and getting better all the time!</b> We're always
                listening to our customers.
              </p>
            </Col>
          </Row>
        </section>

        <section id="testimonials" className="section gray-bg">
          <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
            <Col lg={8}>
              <h3 className="h1 m-15px-b">The Reliable and Trusted Choice</h3>
              <p className="m-0px font-2">
                You'll be in good company: See why <b>over 1,000 customers</b>{" "}
                have chosen or switched to Taplist.io.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Testimonial
              customerName={<>Pub Dog Pizza &amp; Drafthouse</>}
              customerLocation={"Columbia, MD"}
              customerLogo={data.pubDogLogo}
              description={
                <div>
                  <p>
                    Taplist.io was very easy to set up and we had our first menu
                    created in minutes. A great product for a great price with
                    world-class support.
                  </p>
                </div>
              }
            />
            <Testimonial
              customerName={"Moonraker Brewing Company"}
              customerLocation={"Auburn, CA"}
              customerLogo={data.moonrakerLogo}
              description={
                <div>
                  <p>
                    We've tried other software and self-made templates and
                    Taplist.io is definitely superior. It has made ordering a
                    breeze across the board.
                  </p>
                </div>
              }
            />
            <Testimonial
              customerName={"Bare Arms Brewing"}
              customerLocation={"Waco, TX"}
              customerLogo={data.bareArmsLogo}
              description={
                <div>
                  <p>
                    We've been using Taplist.io for over 3 years now and our
                    staff and customers absolutely love it. It's easy for our
                    staff to make menu changes as our beers change.
                  </p>
                </div>
              }
            />
          </Row>
          <Row className="font-2 font-w-600  justify-content-center text-center">
            <Col lg={8}>
              <p>
                <b>Our risk-free guarantee:</b> We're a friendly, family-run
                team&mdash;and we stand behind our product. All of our plans
                come with our money-back guarantee. If you're not happy, we'll
                make it right or{" "}
                <a href="/refund-policy" target="_new">
                  refund you
                </a>
                .
              </p>
              {/* <p>
                <small>
                Since 2016, fewer than 1% of our customers have <em>ever</em>{" "}
                experienced a service issue or asked for a refund.
                </small>
              </p> */}
            </Col>
          </Row>
        </section>

        {/* <section id="trusted" className="section gray-bg">
          <Container>
            <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
              <Col lg={8}>
                <h3 className="h1 m-15px-b">Trusted by Thousands</h3>
                <p className="m-0px font-2">
                  You'll be in great company choosing us. Since 2018, more and
                  more craft bars and breweries have made the switch to
                  Taplist.io.
                </p>
              </Col>
            </Row>
            <Row className="md-m-25px-b m-45px-b justify-content-center">
              <Col lg={6}>
                <div className="border-radius-10 box-shadow-hover hover-top white-bg p-20px-tb">
                  <div className="p-30px">
                    <h3 className="h1 m-15px-b">
                      It <i>Just Works</i>
                    </h3>
                    <ul className="list-type-02">
                      <li>
                        <i className="fas fa-check"></i>{" "}
                        <b>No clutter: Just your brand, on your menus.</b> We're
                        not promoting a social network. We're here to make you
                        look good.
                      </li>
                      <li>
                        <i className="fas fa-check"></i>{" "}
                        <b>Customizable &amp; high quality.</b> We count
                        everyone from small local breweries to large
                        multi-location operators as customers.
                      </li>
                      <li>
                        <i className="fas fa-check"></i>{" "}
                        <b>Stable, reliable, battle-tested.</b> Our fleet has
                        seen <b>millions of hours of real-world use</b>, in fine
                        venues all across the globe.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div class="border-radius-10 box-shadow-hover hover-top white-bg p-20px-tb">
                  <div class="p-30px">
                    <h3 className="h1 m-15px-b">Easy and Friendly</h3>
                    <ul className="list-type-02">
                      <li>
                        <i className="fas fa-check"></i>{" "}
                        <b>Easy to use, easy to manage.</b> The vast majority of
                        our customers get their menu built in minutes.
                      </li>
                      <li>
                        <i className="fas fa-check"></i>{" "}
                        <b>No &quot;sales BS&quot;.</b> We're not going to call
                        and pressure you, and we{" "}
                        <Link to="/pricing">publish our prices</Link> in plain
                        english.
                      </li>
                      <li>
                        <i className="fas fa-check"></i>{" "}
                        <b>Money-back guarantee:</b> We'll work hard to make
                        sure you're happy, and stand by our{" "}
                        <Link to="/refunds">refund policy</Link> if you're not.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}

        <section id="get-started" className="section">
          <Container>
            <Row className="row md-m-25px-b m-45px-b justify-content-center text-center">
              <Col lg={8}>
                <h3 className="h1 m-20px-b p-20px-b">Get Started For Free</h3>
                <p>
                  <a href={SIGNUP_URL} className="btn btn-success btn-lg">
                    Get Started
                  </a>
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center text-center">
              <Col lg={{ span: 8 }}>
                <p className="m-0px font-2">
                  Start building your menu now, no credit card required!
                  <br />
                  See why more and more classy venues are choosing Taplist.io
                  for their digital menus.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </Skel>
  );
};

export default IndexPage;
